.Product_Page_Content
{
  background-image: url(../../../public/images/ProductPage/driver.webp);
  margin-top: 0%;
    width: 100%;
    height: 85vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
}

.Product_Page_Content .PageHeaderText
{
  margin-right: 2%;
  margin-top: 150px;
    width: 52%;
    margin-left: auto;
}
.PageHeaderText .HaganButton  button p span:nth-child(1)
{
  font-weight: 100;
}

.PageHeaderText .HaganButton  button p span:nth-child(2)
{
  border-bottom: 1px solid white;
  padding-bottom: 8px;
}

.DownloadProfileLink 
{
  text-decoration: none;
  color: white;
  font-size: 1.8rem;
  font-weight: 500;
  margin: 25px 0px 0px 20px;
  display: block;
}
.DownloadProfileLink:hover
{
  color: red;
}
.DownloadProfileLink  span
{
  border-bottom: 2px solid white;
  padding-bottom: 8px;
}
.DownloadProfileLink:hover  span
{
  border-bottom: 2px solid red;
}