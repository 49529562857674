p
{
  margin-bottom: 0px !important;
}
@media screen and (max-width: 350px) {
  html {
    font-size: 35%;
  }
}

/* Styles for mobile */
@media (min-width: 350px) {
  /* Styles for mobile go here */

  html {
    font-size: 48%;
  }
}

/* Styles for small tablets */
@media screen and (min-width: 768px) {
  /* Styles for small tablets go here */

  html {
    font-size: 52%;
  }
}

/* Styles for tablets */
@media screen and (min-width: 1024px) {
  /* Styles for tablets go here */

  html {
    font-size: 55%;
  }
}

/* Styles for laptops and desktops */
@media screen and (min-width: 1400px) {
  /* Styles for laptops and desktops go here */

  html {
    font-size: 62.5%;
  }
}
/* Styles for laptops and desktops */
@media screen and (min-width: 1920px) {
  /* Styles for laptops and desktops go here */

  html {
    font-size: 72.5%;
  }
}

/* Styles for large screens */
@media screen and (min-width: 2560px) {
  /* Styles for large screens go here */

  html {
    font-size: 76.5%;
  }
}