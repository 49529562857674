.NavbarParent {
  padding: 3% 4% 2% 4%;
  z-index:1;
  position: inherit;
  width: 100%;
}

.NavbarItems .HaganButton:nth-child(4) button img {

  width: 12px;
}

.NavbarItems .HaganButton:nth-child(4) button {
  display: flex;
  border: 1px solid white;
  padding: 8px;
  border-radius: 50%;
}

.search_button {
  border: 1px solid black;
  background: white !important;
  border-radius: 50%;
  padding: 10px 12px;
}

.search_button svg {
  height: 15px !important;
}

.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger-menu {
  display: none;
}


/****************************************** 
**************MOBILE MENU *****************
********************************************/

#menu__toggle,
#menu__toggle2,
#menu__toggle3
 {
  opacity: 0;
}

#menu__toggle:checked+.menu__btn>span,
#menu__toggle2:checked+.menu__btn>span,
#menu__toggle3:checked+.menu__btn>span {
  transform: rotate(45deg);
}

#menu__toggle:checked+.menu__btn>span::before,
#menu__toggle2:checked+.menu__btn>span::before,
#menu__toggle3:checked+.menu__btn>span::before {
  top: 0;
  transform: rotate(0deg);
}

#menu__toggle:checked+.menu__btn>span::after,
#menu__toggle2:checked+.menu__btn>span::after,
#menu__toggle3:checked+.menu__btn>span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked~.menu__box,
#menu__toggle2:checked~.menu__box,
#menu__toggle3:checked~.menu__box {
  right: 0 !important;
}

.menu__btn {
  position: fixed;
    top: 38px;
    right: 35px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition-duration: .25s;
}

.menu__btn>span::before {
  content: '';
  top: -8px;
}

.menu__btn>span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  list-style: none;
  background-color: black;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: 0.5s;
}

.menu__item {
  display: block;
  padding: 5px 24px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .25s;
}

.menu__item:hover {
  background-color: #CFD8DC;
}

.menu__box ul {
  list-style-type: none;
  margin-top: 12px;
  padding-left: 0px !important;
  background-color: #0d0d13;
}

.menu__box ul li {
  border-bottom: 1px solid white;
  padding: 10px 15px 10px 10px;
}

.MobTransmissionTab
{
  border-bottom: none !important;
}

.ProductDropdown .menu__box ul li:last-child
{
  border-bottom: none !important;
} 

.Mobile_Gasoline_Tab .dropdown-menu li button
{
font-size: 1.8rem;
}

.menu__box {
  background-image: url(../../../public/images/ProductPage/ProductPageBG.webp);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #0d0d13;
}


.dropdown-menu.show
{
  position: relative !important;
  background-color: transparent;
}

/* @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
   */
#dropdown-basic{
  color: white;
    background: transparent;
    font-weight: 300;
    font-family: Titillium Web,sans-serif;
    border: none;
    padding: 0;
    margin: 8px 45px 0px 0px;
    border-radius: 0;
    width: auto;
    Height: auto;
    text-transform: uppercase;
    white-space: nowrap;
    background: transparent !important;
}


   .ProductDropdown   button
   {
    color: white;
    background: transparent;
    font-weight: 300;
    font-family: Titillium Web,sans-serif;
    border: none;
    padding: 0;
    margin: 8px 45px 0px 0px;
    border-radius: 0;
    width: auto;
    Height: auto;
    text-transform: uppercase;
    white-space: nowrap;
    background: transparent !important;
   }
   .dropdown-menu.show
   {
    transform: translate3d(10px, 0.5px, 0px) !important;
   }


   .Gasoline_dropdown .HaganButton a button,
   .Diesel_dropdown .HaganButton a button
{
  font-size: 1.8rem !important;
}

.MobileDownloadProfile .DownloadProfileLink 
{
  color: white;
  background: transparent;
  font-size: 2.2rem;
  font-weight: 300;
  font-family: Titillium Web,sans-serif;
  border: none;
  padding: 0;
  margin: 10px 45px 0px 0px;
  border-radius: 0;
  width: auto;
  Height: auto;
  text-transform: uppercase;
  white-space: nowrap;
}
.MobileDownloadProfile .DownloadProfileLink span
{
  border-bottom: none;
}


/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/

/******************* MEDIA QUERIES OF 768 ***********************/



@media screen and (max-width: 992px) 
{
  .hamburger-menu {
    display: block;
    z-index: 2;
  }

  .NavbarItems {
    display: none !important;
  }
}

/******************* MEDIA QUERIES OF 768 ***********************/



@media screen and (max-width: 767px) {
  .NavbarLogo .HaganButton a button img
  {
    width: 85%;
  }
  

  .NavbarParent {
    padding: 2%;
    z-index: 1;
    position: inherit;
    width: 100%;
  }
  .show.dropdown .ProductPageSideNav
  {
    display: block !important;
  }
}



/******************* MEDIA QUERIES OF 576 ***********************/

@media screen and (max-width: 576px) 
{
  .NavbarParent {
    padding: 6% 0%;
  }
  .NavbarLogo .HaganButton a button img
  {
    width: 75%;
  }
  .menu__box 
  {
    background-size: 150%;
  }
}

