.swiper-vertical>.swiper-pagination-bullets
{
    right: 2% !important;
}

.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap,6px) 0;
    display: block;
    width: 15px !important;
    height: 15px !important;
    background: white !important;
}

.HomePageBody,
.HomePageSlide2,
.HomePageSlide3
{
    padding-top: 120px;
}



/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/

/******************* MEDIA QUERIES OF 768 ***********************/


@media screen and (max-width: 768px) 
{
    .HomePageBody,
    .HomePageSlide2
    {
        padding-top: 50px;
    }
    .HomePageSlide3
    {
        padding-top: 80px;
    }
}



.HomePageBody .PageHeaderText {
    /* Define initial position */
    transform: translateX(110%);
    transition: transform 0.4s ease-in-out;
  }
  .AboutUsSlideLeft {
    /* Define initial position */
    transform: translateX(-110%);
    transition: transform 0.5s ease-in-out;
  }

   /* Animation for sliding in */
   .slide-in {
    transform: translateX(0%) !important;
  }

  .Category1 {
    /* Define initial position */
    transform: translateY(110%);
    transition: transform 0.5s ease-in-out;
    opacity: 0;
  }

  .Category2 {
    /* Define initial position */
    transform: translateY(320%);
    transition: transform 0.7s ease-in-out;
    opacity: 0;
  }

  .Category3 {
    /* Define initial position */
    transform: translateY(430%);
    transition: transform 0.9s ease-in-out;
    opacity: 0;
  }

     /* Animation for sliding in */
     .slide-top {
        transform: translateY(0%);
        opacity: 1;
      }
    
  
 

  