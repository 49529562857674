.GasolineEngineOil.visible {
  animation: heightIncrease 1.2s forwards;
}

.DieselEngineOil.visible {
  animation: heightIncreaseDiesel 1.2s forwards;

}

.GasolineEngineOil.reverse {
  animation: myReverseDiesel 0.4s forwards;
}

.DieselEngineOil.reverse {
  animation: myReverseDiesel 0.4s forwards;
}

.GasolineEngineOil.visible .GasolineEngineOil,
.DieselEngineOil.visible .GasolineEngineOil {
  display: block !important;
  overflow: visible;
}

.ShowOnClick {
  position: relative;
  z-index: 9;
  background-color: #0d0d13;
}

/* FOR THE GASOLINE TAB */
@keyframes heightIncrease {
  0% {
    height: 0px;
  }

  40% {
    height: 210px;
  }

  100% {
    height: 190px
  }
}

@keyframes myReverse {
  0% {
    height: 190px;
  }

  100% {
    height: 0px;
  }
}


/* FOR THE DEISEL TAB */
@keyframes heightIncreaseDiesel {
  0% {
    height: 0px;
  }

  40% {
    height: 160px;
  }

  100% {
    height: 140px
  }
}

@keyframes myReverseDiesel {
  0% {
    height: 140px;
  }

  100% {
    height: 0px;
  }
}


/* SIDE BAR STYLES */
.GasolineEngineOil1,
.GasolineEngineOil2,
.GasolineEngineOilTab,
.DieselEngineOilTab {
  display: flex;
}

.GasolineEngineOil1:hover .HaganButton button,
.GasolineEngineOil2:hover .HaganButton button,
.GasolineEngineOilTab:hover .HaganButton button p,
.DieselEngineOilTab:hover .HaganButton button p {
  color: #FFC424 !important;
}

.GasolineEngineOil1 svg,
.GasolineEngineOil2 svg,
.GasolineEngineOilTab svg,
.DieselEngineOilTab svg {
  margin-right: 15px;
  height: 10px;
}

.TabsSvg {
  width: 20px;
  padding-top: 4px;
}

.GasolineEngineOilTab svg {
  transform: rotate(90deg);
}

.GasolineEngineOil1 svg path,
.GasolineEngineOil2 svg path,
.GasolineEngineOilTab svg path,
.DieselEngineOilTab svg path {
  fill: white;
}

.GasolineEngineOil1:hover svg path,
.GasolineEngineOil2:hover svg path,
.GasolineEngineOilTab:hover svg path,
.DieselEngineOilTab:hover svg path {
  fill: #FFC424;
}

.GasolineEngineOil,
.DieselEngineOil {
  margin-left: 35px;
  height: 0px;
}

.GasolineEngineOilTab,
.GasolineEngineOil1,
.GasolineEngineOil2,
.ProductSideNavLink2 {
  /* width: 424px; */
  border-bottom: 1px solid;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 10px;
}

/* .GasolineEngineOil1,
  .GasolineEngineOil2
  {
    width: 422px !important;
  } */


/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/

/******************* MEDIA QUERIES OF 1050 ***********************/


@media screen and (max-width: 1250px) {

  .GasolineEngineOil,
  .DieselEngineOil {
    margin-left: 10px;
    height: 0px;
  }

  .Page_Side_nav {
    width: 32% !important;
  }
}


@media screen and (max-width: 767px) {
  .ProductPageSideNav {
    display: none;
  }
}


.GasolineEngineOilTab .HaganButton button:hover,
.DieselEngineOilTab .HaganButton button:hover {
  /* mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .2) 30%, #000 50%, rgba(0, 0, 0, .2) 70%); */
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 1.2) 0%, #000 20%, rgba(0, 0, 0, .2) 80%);
  mask-size: 200%;
  animation: shine 2s infinite;
  transition: 0.1s;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}