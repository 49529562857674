
.DieselProductListSwipper .swiper,
.TransmissionProductListSwipper  .swiper 
{
    height: 82vh !important;
    width: 85%;
    position: static !important;
}
.swiper-slide
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-slide .HaganButton
{
    width: 88%;
}

.DieselProductListSwipper .swiper  .swiper-button-prev,
.TransmissionProductListSwipper  .swiper .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset,10px) !important;
    right: auto;
    font-size: 2.2rem;

    border: 1px solid;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding-right: 5px;
}

.DieselProductListSwipper .swiper .swiper-button-next,
.TransmissionProductListSwipper  .swiper .swiper-button-next  {
    right: var(--swiper-navigation-sides-offset,10px) !important;
    left: auto;
    border: 1px solid;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.DieselProductListSwipper .swiper .swiper-button-next:hover,
.TransmissionProductListSwipper  .swiper .swiper-button-next:hover, 

.DieselProductListSwipper .swiper  .swiper-button-prev:hover,
.TransmissionProductListSwipper  .swiper .swiper-button-prev:hover
{
    background: #565656;
}



.TransmissionProductListSwipper  .swiper  .swiper-button-prev:after,
.TransmissionProductListSwipper  .swiper  .swiper-button-next:after,
.DieselProductListSwipper .swiper  .swiper-button-prev:after,
.DieselProductListSwipper .swiper  .swiper-button-next:after

{
    font-size: 2.5rem !important;
}

.DieselProductListSwipper .Product_List,
.TransmissionProductListSwipper .Product_List
{
    display: none;
}


/* FOR GEAR PRODUCT */
.Product_List.GearProductList .HaganButton
{
display: flex;
justify-content: center;
}

/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/
/******************* MEDIA QUERIES OF 992 ***********************/

@media screen and (max-width: 992px) 
{
    .DieselProductListSwipper .swiper .swiper-button-next, .TransmissionProductListSwipper .swiper .swiper-button-next
    {
        right: var(--swiper-navigation-sides-offset,18px) !important;
        left: auto;
    }
    .DieselProductListSwipper .swiper .swiper-button-prev, .TransmissionProductListSwipper .swiper .swiper-button-prev
    {
        left: var(--swiper-navigation-sides-offset,20px) !important;
        right: auto;
    }
    
}


/******************* MEDIA QUERIES OF 768 ***********************/

@media screen and (max-width: 768px) 
{

    .DieselProductListSwipper .swiper,
    .TransmissionProductListSwipper .swiper 
{
    display: none;
}
.DieselProductListSwipper .Product_List,
.TransmissionProductListSwipper .Product_List
{
    display: block;
}
.Product_List .HaganButton a button
  {
    margin-bottom: 50px !important;
  }

}