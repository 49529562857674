.HaganBrakePadPage .ProductImage {
  padding-top: 25rem;
  margin-left: 70px;
}


/******************* MEDIA QUERIES OF 576 ***********************/


@media screen and (max-width: 576px) {

.HaganBrakePadPage
{
  background-image: none;
}
}