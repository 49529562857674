.PoaSeriesProductList,
.SemiSyntheticProductList,
.MineralsProductOutlet,
.TransmissionProductOutlet,
.DieselProductList,
.MotorCycleProductList,
.DieselHydProductList,
.HaganBrakePadPage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  /* Initially off-screen to the left */
  transition: left 0.4s ease-in-out;
  /* Transition for slide effect */
  background-color: #0d0d13;
  z-index: 0;
  width: 100%;
  min-height: 80vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.Product_List {
  height: 83vh;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.Product_List .HaganButton {
  width: 30%;
}

.Product_List .HaganButton img,
.swiper-slide .HaganButton img {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.Product_List .HaganButton img:hover,
.swiper-slide .HaganButton img:hover {
  transform: scale(1.05);
  transition: 0.3s ease-in-out;
}



.PoaSeriesProductList,
.HaganBrakePadPage {
  background-image: url(../../../public/images/PaoSeriesPage/pao_bg1.png);
}

/* Apply the animation when the component is mounted */
.PoaSeriesProductList.show,
.SemiSyntheticProductList.show,
.MineralsProductOutlet.show,
.TransmissionProductOutlet.show,
.DieselProductList.show,
.MotorCycleProductList.show,
.DieselHydProductList.show,
.ProductDetails .ProductDetailsInner,
.HaganBrakePadPage.show {
  left: 0%;
}


/* Apply the animation when the component is mounted */
.PoaSeriesProductList.show,
.SemiSyntheticProductList.show,
.MineralsProductOutlet.show,
.TransmissionProductOutlet.show,
.DieselProductList.show,
.MotorCycleProductList.show,
.DieselHydProductList.show,
.ProductDetails .ProductDetailsInner,
.HaganBrakePadPage.show {
  animation: slideLeft 1.2s forwards;
  position: absolute;
}

.ProductImage {
  animation: slideLeft 1.8s forwards;
  position: absolute;
}

/* FOR THE GASOLINE TAB */
@keyframes slideLeft {
  0% {
    left: 100%;
  }

  60% {
    left: -3%;
  }

  100% {
    left: 0%;
  }
}

.ProductDetails,
.ProductDetailsInner {
  width: 40%;
}

.ProductImage {
  position: relative;
  width: 50%;
}

.ProductProvides {
  padding-bottom: 50px;
}


/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/

@media screen and (max-width: 992px) {
  .ProductDetails {
    padding-left: 2%;
  }

  .Product_List .HaganButton a button img {
    width: 80% !important;
  }

  .Product_List .HaganButton a button {
    margin: 0;
  }

}

/******************* MEDIA QUERIES OF 768 ***********************/


@media screen and (max-width: 767px) {

  .PoaSeriesProductList,
  .SemiSyntheticProductList,
  .DieselHydProductList,
  .HaganBrakePadPage {
    background-position: 100% 20%;
  }

  .ProductDetails,
  .ProductDetailsInner {
    width: 100%;
    padding: 0px 35px 0pc 25px;
  }

}

/******************* MEDIA QUERIES OF 576 ***********************/


@media screen and (max-width: 576px) {
  .ProductImage {
    width: auto;
  }
  .ProductDetails .ProductDetailsInner {
    animation: slideLeft 1.5s forwards;
  }
  
  .ProductImage {
    animation: slideLeft 1.3s forwards;
  }

  .PoaSeriesProductList,
  .SemiSyntheticProductList,
  .DieselHydProductList,
  .HaganBrakePadPage {
    background-position: 100% 12%;
  }

  .Product_List {
    display: block;
  }

  .Product_List .HaganButton {
    width: auto;
  }

  .Product_List .HaganButton a {
    width: 100%;
    display: grid;

  }

  .Product_List .HaganButton a button img {
    width: 50% !important;
  }

  .MotorCycleProductList .Product_List .HaganButton a button img {
    width: 90% !important;
  }

  .Product_List .HaganButton a button {
    margin-bottom: 50px;
  }

}