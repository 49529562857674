.TopParent {
  width: 100%;
  min-height: 100vh;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #0d0d13;
  overflow: auto;
  overflow-x: hidden;
}

.ProductPageParent {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0;
  left: 100%;
  /* Initially off-screen to the left */
  transition: left 0.6s ease-in-out;
  /* Transition for slide effect */
  background-color: #0d0d13;
}

/* Apply the animation when the component is mounted */
.ProductPageParent.show
 {
  /* left: 0%; */
  animation:slideLeft 1.2s forwards;
  top: 15vh;
}

/* FOR THE GASOLINE TAB */
@keyframes slideLeft {
  0% {
    left: 100%;
  }
  60% {
    left: -6%;
  }
  100%
  {
    left:0%;
  }
}

.ProductPageParent .ProductPageBody {
  display: flex;
  justify-content: space-between;
}

.Page_Side_nav {
  padding: 2%;
  width: 28%;
}

.Pages_Content {
  width: 68%;
  margin-left: 2rem;
  position: relative;
  background-color: #0d0d13;
}
.ProductImage
{
  padding-top: 120px;
  margin-left: 70px;
}



/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/

/******************* MEDIA QUERIES OF 768 ***********************/


@media screen and (max-width: 992px) 
{
  .Page_Side_nav {
    display: none;
  }
  
  .Pages_Content {
    width: 100%;
  }
}

/******************* MEDIA QUERIES OF 768 ***********************/


@media screen and (max-width: 767px) {
  
  .ProductPageParent.show 
{
  z-index: 0;
}


  .Pao-0w20Parent {
    flex-direction: column-reverse !important;
  }

  .ProductDetails {
    width: 100% !important;
    padding: 0px 15px 50px 15px !important;
  }
  .ProductImage img
  {
    width: 38% !important;
  }

  .PoaSeriesProductList, .SemiSyntheticProductList, .MineralsProductOutlet, .TransmissionProductOutlet
  {
    background-size: contain !important;
  }
  .ProductImage
  {
    padding-left: 25px;
    margin-bottom: 25px;
  }
  .ProductDetails {
    width: 100% !important;
    padding: 0px 35px 50px 35px !important;
  }
}

/******************* MEDIA QUERIES OF 576 ***********************/

@media screen and (max-width: 576px) 
{
  .pages_Content
  {
margin-left: 0rem;
  }
  .ProductDetails {
    width: 100% !important;
    padding: 0px 35px 50px 35px !important;
}
.ProductImage {
  padding-top: 30px;
  margin-left: 0px;
}
.ProductImage img
{
  width: 58% !important;
}
.MotorCycleProductList   .ProductImage img {
  width: 85% !important;
}
}