.HomePageParent {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    height: 100vh;
    overflow: hidden;

}

.HomePageBody {
    top: 0;
    background-image: url(../../../public/images//HomePage/HomePageBG.webp);
    width: 100%;
    height: 100%;
    padding: 0% 2%;
    background-size: 100% 100%;
    position: relative;
}

.HomePageSlide2,
.HomePageSlide3 {
    top: 0;
    width: 100%;
    height: 100%;
}

.HaganBrakePadsLink {
    position: absolute;
    bottom: 5%;
}

.linkDiv {
    position: relative;
    width: 36%;
}

.linkDiv .HaganButton {
    width: 100% !important;
}

.MovingArrows {
    position: absolute;
    top: 60%;
    left: 32%;
    width: 6%;
}

.linkDiv:hover .MovingArrows img {
    transition: 0.3s ease-in-out;
    animation: LinkAnimation 1s linear infinite;
}

.MovingArrows img {
    transition: 0.3s ease-in-out;
}

/* HOME SLIDE 2 */
.HomePageSlide2 {
    background-color: #e3e3e9;
}

.HomePageSlide2 .NavbarParent .NavbarItems .HaganButton a button {
    color: black !important;
}

.HomePageSlide2 .NavbarParent .NavbarItems .searchDiv input {
    border: 1px solid black !important;
}

.AboutUsSlideLeft p:nth-child(1) span {
    color: red;
    font-weight: 400;
}

.AboutUsSlide,
.BigText,
.HaganOilCategory {
    padding: 2% 4%;
}

.AboutUsSlideLeft {
    width: 44%;
    padding-top: 25px;
    z-index: 1;
}

.AboutUsSlideRight {
    width: 50%;
    position: relative;
    margin-right: 25px;
}

.AboutUsSlideRightBG {
    background-image: url(../../../public/images/AboutUs/aboutusbg.png);
    height: 100vh;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.AboutusWhiteBlur {
    background-color: #fff;
    border-radius: 50%;
    bottom: 0;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    height: 80%;
    left: -8%;
    position: absolute;
    width: 85%;
    z-index: 0;

    /* animation: myfirst 5s infinite;
    animation-direction: alternate; */
}


/* @keyframes myfirst {
    0% {
        background: rgb(250, 162, 162);
        left: 2px;
        top: 122px;
        width: 85%;
        height: 80%;
    }

    25% {
        background: rgb(247, 181, 181);
        left: 5px;
        top: 125px;
        width: 86%;
        height: 81%;
    }

    50% {
        background: rgb(250, 162, 162);
        left: 8px;
        top: 128px;
        width: 87%;
        height: 82%;
    }

    75% {
        background: rgb(202, 166, 166);
        left: 5px;
        top: 125px;
        width: 86%;
        height: 81%;
    }

    100% {
        background: rgb(250, 162, 162);
        left: 2px;
        top: 122px;
        width: 85%;
        height: 80%;
    }
} */


/* HOME SLIDE 3 */
.HomePageSlide3 {
    background-color: black;
    z-index: 3;
}

.BigText p:nth-child(1) span {
    color: red;
}

.Category1,
.Category2,
.Category3 {
    width: 24%;
}

.HaganOilCategory {
    width: 94%;
}


.HomePageBody .PageHeaderText {
    margin-top: 150px;
    width: 40%;
    margin-left: auto;
}

.HomePageSlider {
    /* margin-top: -122px; */
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.swiper {
    height: 100vh !important;
}


/*****************************************************************
******************************** MEDIA QUERIRES *******************
*****************************************************************/

/******************* MEDIA QUERIES OF 1600 ***********************/


@media screen and (max-width: 1600px) {
    .HomePageBody .PageHeaderText {
        width: 60%;
    }

    .HomePageBody {
        background-size: auto;
    }

    /* ABOUTus SLIDER */
    .AboutUsSlideLeft {
        padding-top: 25px;
    }

    .AboutUsSlideLeft P:nth-child(3) {
        margin-top: 25PX !important;
    }

    .AboutUsSlideRight {
        margin-right: 0px;
    }

    .AboutUsSlideRight {
        width: 58%;
    }

    .BigText p:nth-child(1) {
        width: 50%;
        margin-top: 25px;
    }
}

/******************* MEDIA QUERIES OF 1200 ***********************/


@media screen and (max-width: 1200px) {
    .BigText p:nth-child(1) {
        width: 56%;
    }

    .linkDiv {
        width: 40%;
    }
}

/******************* MEDIA QUERIES OF 990 ***********************/


@media screen and (max-width: 992px) {

    .AboutUsSlide,
    .BigText,
    .HaganOilCategory {
        padding: 2% 0% 2% 2%;
    }

    .AboutUsSlideLeft {
        width: 55%;
        padding-top: 65px;
        z-index: 1;
    }

    .AboutusWhiteBlur {
        width: 100%;
        height: 68%;
    }

    .AboutUsSlideRightBG {
        background-position: bottom;
    }

    .BigText p:nth-child(1) {
        width: 66%;
    }

    .Category1,
    .Category2,
    .Category3 {
        width: 28%;
    }

    .linkDiv {
        width: 46%;
    }
}


/******************* MEDIA QUERIES OF 768 ***********************/


@media screen and (max-width: 768px) {
    .HomePageBody .PageHeaderText {
        width: 60%;
    }

    .BigText p:nth-child(1) {
        width: 70%;
    }

    .linkDiv {
        width: 48%;
    }

    .HaganBrakePadsLink {
        position: absolute;
        bottom: 8%;
    }
}

/******************* MEDIA QUERIES OF 650 ***********************/

@media screen and (max-width: 650px) {
    .AboutUsSlideRight {
        display: none;
    }

    .AboutUsSlideLeft {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .BigText p:nth-child(1) {
        width: 78%;
        font-size: 5rem;
    }

    .HaganOilCategory {
        flex-direction: column;
    }

    .Category1,
    .Category2,
    .Category3 {
        width: 100%;
        margin-top: 20px;
    }

    .BigText,
    .HaganOilCategory {
        padding-left: 7% !important;
        padding-right: 7% !important;
    }
    .linkDiv .MovingArrows img {
        transition: 0.3s ease-in-out;
        animation: LinkAnimationMobile 1s linear infinite;
    }
}



/******************* MEDIA QUERIES OF 576 ***********************/

@media screen and (max-width: 576px) {
    .HomePageBody .PageHeaderText {
        width: 96%;
    }

    .BigText p:nth-child(1) {
        width: 100%;
        font-size: 4rem;
    }

    .linkDiv {
        width: 60%;
    }

    .HaganBrakePadsLink {
        bottom: 10%;
    }
}

/******************* MEDIA QUERIES OF 375 ***********************/

@media screen and (max-width: 375px) {

    .BigText p:nth-child(1) {
        font-size: 3rem;
    }

    .Category1,
    .Category2,
    .Category3 {
        margin-top: 12px;
    }

    .Category1 p:nth-child(1),
    .Category2 p:nth-child(1),
    .Category3 p:nth-child(1) {
        margin-bottom: 8px !important;
    }

}







.circle {
    position: absolute;
    transform: translate(-50%, -50%);
    background: red;
    border-radius: 50%;
    animation: animate 0.4s linear forwards;
}

@keyframes animate {
    0% {
        width: 0px;
        height: 0px;
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        width: 80px;
        height: 80px;
        opacity: 0;
    }
}




@keyframes LinkAnimation {
    0% {
        opacity: 0.2;
        scale: 1.1;
        transform: translateX(0px);
    }

    70% {
        opacity: 1;
        scale: 1.1;
    }

    100% {
        opacity: 0.2;
        transform: translateX(35px);
        scale: 1.1;
    }
}

@keyframes LinkAnimationMobile {
    0% {
        opacity: 0.2;
        scale: 1.1;
        transform: translateX(-5px);
    }

    70% {
        opacity: 1;
        scale: 1.1;
    }

    100% {
        opacity: 0.2;
        transform: translateX(25px);
        scale: 1.1;
    }
}